<script>
export default {
  name: 'InfoBox',

  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    body: {
      type: String,
      required: false,
      default: '',
    },
    variant: {
      required: false,
      validator: variant => ['info', 'primary', 'success', 'warning', 'danger'].includes(variant),
      default: 'info',
    },
  },

  computed: {
    infoBoxVariant() {
      return { [`border-${this.variant}`]: this.variant }
    },
  },
}
</script>

<template>
  <div class="info-box"
    :class="infoBoxVariant"
    data-testid="info-box">
    <slot name="header">
      <h5 v-if="title"
        class="m-0 mb-1 font-weight-500">
        {{ $t(title) }}
      </h5>
    </slot>
    <slot>
      <p v-if="body"
        class="m-0 font-weight-400">
        {{ $t(body) }}
      </p>
    </slot>
    <slot name="footer" />
  </div>
</template>

<style lang="scss" scoped>
.info-box {
  background-color: var(--color-white);
  border-radius: var(--border-radius-base);
  padding: 2rem;
  text-align: center;
  width: fit-content;
  margin: auto;
  line-height: normal;
}
</style>
