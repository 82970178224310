<template>
  <section v-loading.fullscreen="loading"
    :element-loading-text="$t('services.loading-services')">
    <edit-megaport v-if="!loading && checkServiceAvailable()" />
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import EditMegaportComponent from '@/components/EditMegaport.vue'
import { resolveServicesPage } from '@/utils/MapDataUtils.js'

export default {
  components: {
    'edit-megaport': EditMegaportComponent,
  },

  computed: {
    ...mapState('Services', ['servicesReady']),
    ...mapGetters('Auth', ['accountType']),
    ...mapGetters('Auth', ['isPartnerAccount']),
    ...mapGetters('ApplicationContext', ['companyContextLoading']),
    ...mapGetters('Services', ['myPorts']),

    loading() {
      return !this.servicesReady || !this.accountType
    },
    allowNotify() {
      return !this.isPartnerAccount && !this.companyContextLoading
    },
  },

  methods: {
    checkServiceAvailable() {
      const serviceId = this.$route.params.serviceId
      if (!serviceId) {
        return true
      }
      const hasPort = !!this.myPorts.find(port => port.productUid === serviceId)
      if (!hasPort) {
        const props = {
          title: this.$t('general.type-not-found', { type: this.$t('productNames.port') }),
          message: this.$t('general.type-not-found-redirect', { type: this.$t('productNames.port') }),
          type: 'error',
          duration: 3000,
        }
        this.allowNotify && this.$notify(props)
        this.$router.push(resolveServicesPage())
        return false
      }
      return true
    },
  },
}
</script>
